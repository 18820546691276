import { useState, useEffect } from 'react';
import '../Style/Experience.css';

import Threesoft from '../images/ThreesoftLogo.png';
import husqvarnaLogo from '../images/husqvarnaLogo.png';
import Est from '../images/EST.png';
import Csg from '../images/CSG.png';
import lss from '../images/lss.png';
import Ophelia from '../images/Ophelia.png';

const experienceData = [
    {
      title: "👨‍💼 CEO & Founder – ThreeSoft AB",
      image: Threesoft,
      description: "Founder of a modern IT company delivering tailored web, app, and AI solutions. Responsible for business strategy, technical development, and driving innovation to help clients succeed in a digital-first world.",
      link: "https://threesoft.se/"
    },
    {
      title: "📱 Android Developer – Husqvarna Group",
      image: husqvarnaLogo,
      description: "Completed a 7-week internship focused on Android development. Contributed to app performance, UI improvements, and backend integration within a leading global tech environment.",
    },
    {
      title: "📈 Vice CEO – Elite Sales Team INT AB",
      image: Est,
      description: "Co-led a retail-focused company. Oversaw daily operations, managed teams, and supported business growth through strategic planning, leadership, and financial coordination.",
    },
    {
      title: "🛡️ Security Officer – CSG AB",
      image: Csg,
      description: "Worked night shifts in Stockholm’s public transport. Ensured safety by handling disturbances, collaborating with police, and maintaining a calm and professional presence in challenging situations.",
    },
    {
      title: "🩺 Psychiatric Nurse Assistant – Region Jönköping & Stockholm",
      image: lss,
      description: "Provided care in psychiatric and hospital settings. Supported patients with empathy and stability, assisted in daily routines, medication, and crisis management alongside healthcare teams.",
    },
    {
      title: "🧠 Founder & Product Lead – Project Ophelia",
      image: Ophelia,
      description: "Initiated and led the development of an AI platform to support victims of psychological abuse. Directed product planning, programming, and data work, supported by experts from tech, healthcare, and cloud sectors.",
      link: "https://projectophelia.se/"
    }
  ];
  

const initialBoard = Array(9).fill(null);
const winningCombos = [
  [0, 1, 2], [3, 4, 5], [6, 7, 8],
  [0, 3, 6], [1, 4, 7], [2, 5, 8],
  [0, 4, 8], [2, 4, 6]
];

const Experience = ({ onClose }) => {
  const [board, setBoard] = useState(initialBoard);
  const [playerTurn, setPlayerTurn] = useState(true);
  const [winner, setWinner] = useState(null);
  const [unlocked, setUnlocked] = useState(false);
  const [selectedExp, setSelectedExp] = useState(null);

  // 🔄 Förladda alla bilder
  useEffect(() => {
    [Threesoft, husqvarnaLogo, Est, Csg, lss, Ophelia].forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  // 🔄 Fade-in effekt när bild laddats
  const handleImageLoad = (e) => {
    e.target.classList.add('loaded');
  };

  const checkWinner = (b) => {
    for (let combo of winningCombos) {
      const [a, b1, c] = combo;
      if (b[a] && b[a] === b[b1] && b[a] === b[c]) return b[a];
    }
    return b.includes(null) ? null : 'draw';
  };

  const handleClick = (index) => {
    if (!playerTurn || board[index] || winner) return;
    const newBoard = [...board];
    newBoard[index] = 'X';
    setBoard(newBoard);
    setPlayerTurn(false);
  };

  const botMove = (b) => {
    const available = b.map((v, i) => v === null ? i : null).filter(i => i !== null);
    if (available.length === 0) return;
    const move = available[Math.floor(Math.random() * available.length)];
    b[move] = 'O';
    setBoard([...b]);
    setPlayerTurn(true);
  };

  useEffect(() => {
    const result = checkWinner(board);
    if (result) {
      setWinner(result);
      if (result === 'X') {
        setTimeout(() => setUnlocked(true), 800);
      }
    } else if (!playerTurn) {
      setTimeout(() => botMove([...board]), 500);
    }
  }, [board, playerTurn]);

  const reset = () => {
    setBoard(initialBoard);
    setWinner(null);
    setPlayerTurn(true);
    setUnlocked(false);
  };

  return (
    <div className="experience-wrapper">
      <button className="close-btn" onClick={onClose}>✕</button>

      {!unlocked ? (
        <>
          <h2>🎮 Beat Me to Unlock My Experience</h2>
          <p className="instruction">
            You must <strong>win</strong> against me in Tic Tac Toe to unlock my professional journey 👨‍💻
          </p>

          <div className="board">
            {board.map((cell, i) => (
              <div key={i} className="cell" onClick={() => handleClick(i)}>
                {cell}
              </div>
            ))}
          </div>

          {winner && winner !== 'X' && (
            <div className="game-result">
              <h3>{winner === 'draw' ? "It's a draw!" : "You lost! Try again."}</h3>
              <button onClick={reset} className="reset-button">Try Again</button>
            </div>
          )}
        </>
      ) : (
        <>
          <h2>My Journey & Experience</h2>
          <p className="info-text">Click on a box to see more about my roles, team efforts, and key projects 🌟</p>

          <div className="experience-details">
            {experienceData.map((exp, index) => (
              <div key={index} className="exp-block" onClick={() => setSelectedExp(exp)}>
                <img src={exp.image} alt={exp.title} onLoad={handleImageLoad} />
                <h3>{exp.title}</h3>
              </div>
            ))}
          </div>


        </>
      )}

      {selectedExp && (
        <div className="experience-popup">
          <div className="popup-content">
            <button className="popup-close" onClick={() => setSelectedExp(null)}>✕</button>
            <img src={selectedExp.image} alt={selectedExp.title} onLoad={handleImageLoad} />
            <h3>{selectedExp.title}</h3>
            <p>{selectedExp.description}</p>
            {selectedExp.link && (
              <a
                href={selectedExp.link}
                target="_blank"
                rel="noopener noreferrer"
                className="exp-link"
              >
                🌐 Visit Website
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Experience;
