// src/Components/ContactMe.js
import React from 'react';
import '../Style/ContactMe.css';

const ContactMe = ({ onClose }) => {
  return (
    <div className="tech-contact-wrapper">
      {/* Close button in the top-right corner */}
      <button className="close-btn" onClick={onClose}>
        ✕
      </button>

      <h2>🤝 Let’s Connect</h2>
      <p className="lux-intro">
        Thank you for stopping by!
        <br />
        Feel free to reach out via any channel below.
      </p>

      <div className="lux-contact-icons">
        {/* Phone */}
        <a
          href="tel:0762316606"
          className="icon phone"
          title="Call Me"
        >
          📞
        </a>

        {/* Email */}
        <a
          href="mailto:aryapoureisa@gmail.com"
          className="icon mail"
          title="Send Me an Email"
        >
          ✉️
        </a>

        {/* LinkedIn */}
        <a
          href="https://www.linkedin.com/in/arya-eisa-292710191/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon linkedin"
          title="Visit My LinkedIn"
        >
          in
        </a>
      </div>
    </div>
  );
};

export default ContactMe;
